const timeBetween = 100
const position = 0.9

var views = []
var viewsByElem = {}
var viewables = []
var showTimeout = null
var ticking = false
var showing = false
var windowHeight = window.innerHeight
var scrollTop = getScrollTop()
var lastTime = Date.now()
var loaded = false

var showHandler = null

function defereScroll() {
  if (!ticking)
    window.requestAnimationFrame(scroll);
  ticking = true;
}

function getScrollTop() {
  return window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
}

function scroll() {
  //console.log('scroll');
  ticking = false;
  scrollTop = getScrollTop();
  var height = windowHeight * position;
  for (var i = views.length - 1; i >= 0; i--) {
    var view = views[i];
    if (view.top - height - scrollTop < 0) {
      viewables.push(view);
      views.splice(i, 1);
    }
  }

  if (showing == false && viewables.length > 0)
    showHandler = window.requestAnimationFrame(show);
}

function sortByTop(a, b) {
  var atop = Math.abs(a.top - scrollTop);
  var btop = Math.abs(b.top - scrollTop);
  if (atop < btop)
    return -1;
  if (atop > btop)
    return 1;
  return 0;
}

function resize() {
  console.log('resize');
  windowHeight = window.innerHeight;
  scrollTop = getScrollTop();
  for (var i = views.length - 1; i >= 0; i--) {
    var view = views[i];
    view.top = view.elem.getBoundingClientRect().top + scrollTop;
  }
}

function show() {
  //console.log('show');
  showing = true;
  var now = Date.now();
  if (now - lastTime > timeBetween) {
    //console.log(now - lastTime);
    viewables.sort(sortByTop);
    var view = viewables.shift();
    view.elem.classList.add('showed')
    if (view.callback !== null && typeof view.callback == 'function')
      view.callback.call(view.elem)
    lastTime = now;
  }

  if (viewables.length == 0)
    showing = false;
  else
    showHandler = window.requestAnimationFrame(show);
}

function on(elem, fn) {
  if (viewsByElem[elem])
    viewsByElem[elem].callback = fn
}

export default function scrollshow(selectors = []) {
  selectors = [].concat(selectors)
  selectors.push('[data-show]')

  views = []
  viewsByElem = {}
  viewables = []
  showTimeout = null
  ticking = false
  showing = false
  windowHeight = window.innerHeight
  scrollTop = getScrollTop()
  lastTime = Date.now()
  window.cancelAnimationFrame(showHandler)

  var elem = document.querySelectorAll(selectors.join(', '))
  for (var i = 0, l = elem.length; i < l; i++) {
    var view = {
      top: 0,
      elem: elem[i],
      callback: null
    }
    views.push(view)
    viewsByElem[elem[i]] = view
  }

  // var images = document.querySelectorAll('img, video')
  // for (var j = 0, lj = images.length; j < lj; j++) {
  //   images[j].removeEventListener('load', resize, false)
  //   images[j].addEventListener('load', resize, false)
  // }

  window.addEventListener('resize', resize, false)
  window.addEventListener('scroll', defereScroll, false)
  window.addEventListener('load', resize, true)

  resize()
  scroll()
}
